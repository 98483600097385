import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { TranslateService, NotificationService, ConfirmService } from 'swx.front-end-lib';
import { RootScope } from '../shared/RootScope.service';

@Component({
    templateUrl: 'DispatchAirportProfileDetail.component.html'
})
export class DispatchAirportProfileDetailComponent {
    item;
    returnPath;
    tab;
    selectedSubgroup;
    searchQuery;
    airports = [];
    @ViewChild('ngForm', { static: true }) ngForm;
    tmpKey = 0;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private api: ApiService,
        private translateService: TranslateService,
        private notification: NotificationService,
        private confirmService: ConfirmService,
        public $root: RootScope,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.DispatchAirportProfile.get({ id: copyId });
            this.item.$promise.then(() => delete this.item.Id);
        } else if (isNew) {
            this.item = this.api.DispatchAirportProfile.create({
                Active: true
            });
        } else {
            this.item = this.api.DispatchAirportProfile.get({ id: id });
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.api.ClientAirport.query().$promise.then(airports => {
            this.airports = airports;
        });
    }

    save() {
        this.item.$save().then(() => {
            this.notification.show(this.translateService.translate('The changes have been saved.'));
            this.router.navigateByUrl(this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addGroup() {
        this.item.DispatchAirportGroups = this.item.DispatchAirportGroups || [];
        
        var maxOrder = Math.max.apply(null, this.item.DispatchAirportGroups
            .map(h => h.Order));

        this.item.DispatchAirportGroups.push({
            Order: Math.max(1, maxOrder + 1),
            TmpKey: ++this.tmpKey,
        });

        this.ngForm.form.markAsDirty();
    }

    removeGroup(group) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.item.DispatchAirportGroups.splice(this.item.DispatchAirportGroups.indexOf(group), 1);
            this.selectedSubgroup = null;
            this.ngForm.form.markAsDirty();
        }).catch(() => {});
    }

    addSubgroup(group) {
        group.DispatchAirportGroupSubgroups = group.DispatchAirportGroupSubgroups || [];
        
        var maxOrder = Math.max.apply(null, group.DispatchAirportGroupSubgroups
            .map(h => h.Order));

        group.DispatchAirportGroupSubgroups.push({
            Order: Math.max(1, maxOrder + 1),
            TmpKey: ++this.tmpKey,
        });

        this.ngForm.form.markAsDirty();
    }

    removeSubgroup(subgroup, group) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            group.DispatchAirportGroupSubgroups.splice(group.DispatchAirportGroupSubgroups.indexOf(subgroup), 1);
            this.selectedSubgroup = null;
            this.ngForm.form.markAsDirty();
        }).catch(() => {});
    }

    toggleAirport(selectedSubgroup, airport) {
        if (selectedSubgroup == null) return;

        selectedSubgroup.DispatchAirportGroupSubgroupAirports = selectedSubgroup.DispatchAirportGroupSubgroupAirports || [];

        var index = selectedSubgroup.DispatchAirportGroupSubgroupAirports.findIndex(sga => sga.AirportId === airport.Id);
        if (index !== -1) {
            selectedSubgroup.DispatchAirportGroupSubgroupAirports.splice(index, 1);
        } else {
            selectedSubgroup.DispatchAirportGroupSubgroupAirports.push({
                AirportId: airport.Id
            });
        }

        this.ngForm.form.markAsDirty();
    }

    doesSubgroupContainAirport(selectedSubgroup, airport) {
        if (selectedSubgroup.DispatchAirportGroupSubgroupAirports == null || selectedSubgroup.DispatchAirportGroupSubgroupAirports.length === 0) return false;
        return selectedSubgroup.DispatchAirportGroupSubgroupAirports.some(sga => sga.AirportId === airport.Id);
    }

    getSubgroupNamesWithAirport(airport) {
        if (this.item.DispatchAirportGroups == null) return null;

        var subgroups = '';
        for (let group of this.item.DispatchAirportGroups) {
            if (group.DispatchAirportGroupSubgroups == null) continue;
            for (let subgroup of group.DispatchAirportGroupSubgroups) {
                if (subgroup.DispatchAirportGroupSubgroupAirports == null) continue;
                if (subgroup.DispatchAirportGroupSubgroupAirports.some(sga => sga.AirportId === airport.Id)) {
                    subgroups += ', ' + subgroup.Name;
                }
            }
        }

        return subgroups === '' ? '' : subgroups.substring(2);
    }
}
